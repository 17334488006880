import { memo, useState } from 'react';

import { core, errors, messages } from '@/features/dashboard/localization';

import { Button } from '@/components/ui/button';
import { Card, CardContent } from '@/components/ui/card';
import { useToast } from '@/components/ui/use-toast';

interface Props {
  emailVerified: boolean | undefined;
  // eslint-disable-next-line react/require-default-props
  center?: boolean;
}

export const EmailVerified = ({ emailVerified, center = false }: Props) => {
  const [isDisableResendVerificationBtn, setIsDisableResendVerificationBtn] = useState<boolean>(false);

  const { toast } = useToast();
  const handleEmailVerification = async () => {
    try {
      setIsDisableResendVerificationBtn(true);
      const request = await fetch('/api/resend-email-verification', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      const response = await request.json();

      if (response.error) {
        toast({
          title: errors.emailVerificationSent,
          description: response.currentCounter === 0 ? response.message : errors.msgEmailVerificationSent,
          variant: 'destructive',
        });
      } else {
        toast({
          title: messages.emailSentSuccessfully,
          description: messages.emailVerificationSent,
          variant: 'default',
          className: 'bg-sivarbet-secondary',
        });
      }
    } catch (error) {
      toast({
        title: errors.emailVerificationSent,
        description: errors.msgEmailVerificationSent,
        variant: 'destructive',
      });
    } finally {
      setTimeout(() => {
        setIsDisableResendVerificationBtn(false);
      }, 10000);
    }
  };

  if (emailVerified === undefined) return null;
  return (
    <Card className="w-full text-sivarbet-text rounded-lg border-none bg-sivarbet-secondary-bg">
      <CardContent className={`w-full h-full rounded-lg border-none  flex flex-col items-center ${center ? 'justify-center' : 'justify-between'} p-4 md:p-8 box-border shrink-0`}>
        <div className={`flex-1 flex flex-col rounded-lg bg-transparent justify-start gap-4 ${center ? 'items-center' : 'items-start'}`}>
          {emailVerified ? (
            <>
              <div className="w-7 h-7 rounded-full bg-green500 flex items-center justify-center">
                <span className="icon icon-check text-sm" />
              </div>
              <div className="text-base font-small">
                {core.verifiedEmail}
              </div>
            </>
          ) : (
            <>
              <div className="w-9 h-9 rounded-full bg-sivarbet-primary flex items-center justify-center">
                <div className="text-xl font-medium text-inherit">2</div>
              </div>
              <div className="text-base font-medium">{core.verifyEmail}</div>
              <div className="text-sm font-medium text-sivarbet-secondary-text text-wrap">
                {messages.completeProfileB}
              </div>
            </>
          )}
        </div>
        {emailVerified ? (
          <div className="text-green500 font-medium">{core.verified}</div>
        ) : (
          <Button
            type="button"
            disabled={isDisableResendVerificationBtn}
            onClick={handleEmailVerification}
            className="cursor-pointer border-none py-3 px-5 bg-sivarbet-primary w-full rounded-lg flex items-center justify-center hover:bg-sivarbet-primary-hover mt-auto"
          >
            <div className="text-sm font-medium text-sivarbet-border">
              {core.resendVerification}
            </div>
          </Button>
        )}
      </CardContent>
    </Card>
  );
};

export default memo(EmailVerified);
