import { memo } from 'react';

import Link from 'next/link';

import { core, messages } from '@/features/dashboard/localization';

import { Card, CardContent } from '@/components/ui/card';

interface Props {
  fullProfile: boolean | undefined;
  // eslint-disable-next-line react/require-default-props
  center?: boolean;
}
const FullProfile = ({ fullProfile, center = false }: Props) => {
  if (fullProfile === undefined) return null;

  return (
    <Card className="w-full text-sivarbet-text rounded-lg border-none bg-sivarbet-secondary-bg">
      <CardContent className={`w-full h-full rounded-lg border-none  flex flex-col items-center ${center ? 'justify-center' : 'justify-between'} p-4 md:p-8 box-border shrink-0`}>
        <div className={`flex-1 flex flex-col rounded-lg bg-transparent justify-start gap-4 ${center ? 'items-center' : 'items-start'}`}>
          {fullProfile ? (
            <>
              <div className="w-7 h-7 rounded-full bg-green500 flex items-center justify-center">
                <span className="icon icon-check text-sm" />
              </div>
              <div className="text-base font-small">
                {core.profileCompleted}
              </div>
            </>
          ) : (
            <>
              <div className="w-9 h-9 rounded-full bg-sivarbet-primary flex items-center justify-center">
                <div className="text-xl font-medium text-inherit">1</div>
              </div>
              <div className="text-base font-medium">{core.completeProfile}</div>
              <div className="text-sm font-medium text-sivarbet-secondary-text text-wrap">
                {messages.completeProfileA}
              </div>
            </>
          )}
        </div>
        {fullProfile ? (
          <div className="text-green500 font-medium">{core.completed}</div>
        ) : (
          <Link
            href={`/${core.accountConfigurationUrl}/${core.identityDetailsUrl}`}
            className="w-full rounded-lg bg-sivarbet-primary flex items-center justify-center py-3 px-5 text-sivarbet-border text-sm font-medium mt-auto"
          >
            {core.update}
          </Link>
        )}
      </CardContent>
    </Card>
  );
};

export default memo(FullProfile);
