/* eslint-disable max-lines-per-function */
/* eslint-disable no-console */

'use client';

import React, {
  useEffect,
  useState,
  useRef,
  MutableRefObject,
  useReducer,
  memo,
  Suspense,
} from 'react';

import Link from 'next/link';

import { useSearchParams } from 'next/navigation';
import Script from 'next/script';

import { useSession } from 'next-auth/react';

import { FaRankingStar } from 'react-icons/fa6';
import { GiChart } from 'react-icons/gi';

import { IoHeart } from 'react-icons/io5';
import { MdLiveTv, MdRecommend } from 'react-icons/md';
import { PiShootingStarLight } from 'react-icons/pi';
import useSWR from 'swr';

import {
  BonusDispatcher, BonusStatusCasino, BonusStatusSport, UserKYCLevel,
} from '@/enums/core';
import BonusSteps from '@/features/Bonus/BonusSteps';
import { CategoriesGames } from '@/features/casino/utils';

import { fetchConfirmationPayphone } from '@/features/deposit/services';

import { useAuthStore } from '@/stores/userStore';

import { getDatGames } from '@/utils/core';

import { CarouselData } from '@/interfaces/core';

import {
  AUTHENTICATED_SESSION,
} from '@/constants/core';

import BannerCarousel from '../banner/BannerCarousel';
import LeagueSlider from '../banner/LeagueSlider';
// import SWSmoothCarousel from '../banner/SWSmoothCarousel';
import SWSmoothCarousel from '../banner/SWSmoothCarousel';
import LoadingSpinner from '../common/LoadingSpinner';
import EmailVerificationModal from '../modals/EmailVerificationModal';
import { Skeleton } from '../ui/skeleton';
import { useToast } from '../ui/use-toast';

const HomeTab = () => {
  interface State {
    categories: CarouselData[];
    isFavoritesLoaded: boolean;
    isLoading: boolean;
  }
  const initialState: State = {
    categories: [],
    isFavoritesLoaded: false,
    isLoading: false,
  };

  type Action =
    | { type: 'SET_CATEGORIES'; payload: CarouselData[] }
    | { type: 'SET_LOADING'; payload: boolean }
    | { type: 'SET_FAVORITES_LOADED'; payload: boolean };

  function reducer(state: State, action: Action): State {
    switch (action.type) {
      case 'SET_CATEGORIES':
        return { ...state, categories: action.payload };
      case 'SET_LOADING':
        return { ...state, isLoading: action.payload };
      case 'SET_FAVORITES_LOADED':
        return { ...state, isFavoritesLoaded: action.payload };
      default:
        return state;
    }
  }

  const [state, dispatch] = useReducer(reducer, initialState);
  const [isScriptLoaded, setIsScriptLoaded] = useState<boolean>(false);
  const [isWidgetReady, setIsWidgetReady] = useState<boolean>(false);
  const [isxtremepushSet, setXtremepush] = useState<boolean>(false);
  const [isBonusStepsOpen, setIsBonusStepsOpen] = useState<boolean | undefined>(false);
  const widgetRef = useRef<MutableRefObject<any>>(null);
  const { toast } = useToast();
  const scriptUrl = process.env.NEXT_PUBLIC_SCRIPT_URL as string;
  const integration = process.env.NEXT_PUBLIC_ALTENAR_INTEGRATION as string;
  const { data: session, status } = useSession();
  const { account, bonuses } = useAuthStore();
  // Load all games
  // const loadData = useCallback(async () => {
  //   dispatch({ type: 'SET_LOADING', payload: true });
  //   let categories = [];
  //   const IconCategory: Record<string, React.ReactNode> = {
  //     recomendados: <MdRecommend />,
  //     nuevos: <PiShootingStarLight />,
  //     live: <MdLiveTv />,
  //     crash: <GiChart />,
  //     popular: <FaRankingStar />,
  //   };

  //       if (account.favoriteGames && account.favoriteGames.length > 0) {
  //         const data = await getDatGames(account.favoriteGames, 'home');
  //         const favoriteGames = data.gameData;

  //         categories.push({
  //           headline: 'Favoritos',
  //           items: favoriteGames,
  //           url: '/favoritos',
  //           icon: <IoHeart />,
  //         });
  //       }

  //   // Load categories
  //   if (state.categories.length === 0) {
  //     const data = await getDatGames([], 'home');
  //     const setedCategory = CategoriesGames(data, IconCategory, 'home');
  //     categories = [...categories, ...setedCategory];
  //     dispatch({
  //       type: 'SET_CATEGORIES',
  //       payload: categories,
  //     });
  //   }

  //   dispatch({ type: 'SET_FAVORITES_LOADED', payload: true });
  //   dispatch({ type: 'SET_LOADING', payload: false });
  // }, [state.categories, state.isFavoritesLoaded]);
  const IconCategory: Record<string, React.ReactNode> = {
    recomendados: <MdRecommend />,
    nuevos: <PiShootingStarLight />,
    live: <MdLiveTv />,
    crash: <GiChart />,
    popular: <FaRankingStar />,
  };
  const fetcher = async () => {
    const allGamesData = await getDatGames([], 'home');
    const categories = CategoriesGames(allGamesData, IconCategory, 'home');

    const orderMap: { [key: string]: number } = {
      Popular: 1,
      Recomendados: 2,
      Nuevos: 3,
      Live: 4,
      Crash: 5,
    };
    categories.sort((a: any, b: any) => {
      const orderA = orderMap[a.headline] || 999;
      const orderB = orderMap[b.headline] || 999;
      return orderA - orderB;
    });
    if (account.favoriteGames && account.favoriteGames.length > 0) {
      const favoritesData = await getDatGames(account.favoriteGames, 'home');
      categories.unshift({
        headline: 'Favoritos',
        items: favoritesData.gameData,
        url: '/favoritos',
        icon: <IoHeart />,
      });
    }

    return categories;
  };

  const { data: categories, mutate } = useSWR('gameCategories', fetcher, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    dedupingInterval: 5000, // Dedupe requests within 5 seconds
  });

  const updateFavoritesOptimistically = async (newFavorites: string[]) => {
    const updatedCategories = categories ? [...categories] : [];
    const favoriteIndex = updatedCategories.findIndex((cat) => { return cat.headline === 'Favoritos'; });

    // Update UI immediately
    if (favoriteIndex !== -1) {
      if (newFavorites.length === 0) {
        updatedCategories.splice(favoriteIndex, 1);
      } else {
        updatedCategories[favoriteIndex] = {
          ...updatedCategories[favoriteIndex],
          items: newFavorites.map((gameCode) => { return { identifier: gameCode }; }), // Optimistic items
        };
      }
    } else if (newFavorites.length > 0) {
      updatedCategories.unshift({
        headline: 'Favoritos',
        items: newFavorites.map((gameCode) => { return { identifier: gameCode }; }),
        url: '/favoritos',
        icon: <IoHeart />,
      });
    }

    // Optimistic UI update
    mutate(updatedCategories, false);

    // Background update
    try {
      const favoritesData = await getDatGames(newFavorites, 'home');
      updatedCategories[0].items = favoritesData.gameData;
      mutate(updatedCategories, false); // Re-validate with accurate data
    } catch (error) {
      console.error('Failed to update favorites in the backend:', error);
      // Optionally show a toast to the user
    }
  };

  // Load games
  useEffect(() => {
    if ((window as any).xtremepush) {
      if (!isxtremepushSet) {
        (window as any).xtremepush('event', 'pageview_home');
        setXtremepush(true);
      }
    }
  }, [state.isFavoritesLoaded, isxtremepushSet, setXtremepush]);

  useEffect(() => {
    const initAltenar = async () => {
      const initConfig = {
        integration,
        culture: 'es-ES',
        themeName: 'dark',
      };
      (window as any).altenarWSDK?.init(initConfig);

      (widgetRef as any).current = (window as any).altenarWSDK.addWidget({
        widget: 'WTopEventsCarousel',
        props: {
          onEventSelect: (event: any) => {
            window.location.href = `/deportes?event=${event.id}`;
          },
          onOddSelect: (odd: any) => {
            window.location.href = `/deportes?event=${odd.eventId}`;
          },
          backgroundOverride: {
            sport: {
              66: 'https://cdn.sanity.io/images/mavwf57a/production/69ef2976ee03c5770dd4af31c7625ebaa875a80e-400x141.webp',
              67: 'https://cdn.sanity.io/images/mavwf57a/production/0cb2696d8a088415ee2cdb14e152e9247db47b77-1792x1024.webp',
              68: 'https://cdn.sanity.io/images/mavwf57a/production/8c8e1b0d1e029261a620e0890aee4897eeb87ba2-1792x1024.webp',
              76: 'https://cdn.sanity.io/images/mavwf57a/production/2b74598240c46346d5717df0446ad3a50a3fd75b-1792x1024.webp',
            },
            championship: {
              2936: 'https://cdn.sanity.io/images/mavwf57a/production/5a5b7177a6a5351e49f8247395e92be6f6d91b7a-400x141.png',
              2941: 'https://cdn.sanity.io/images/mavwf57a/production/9554bdda86eeadf7e69113191546c01fe53b3954-400x141.png',
              2942: 'https://cdn.sanity.io/images/mavwf57a/production/0ad213d3f09f1d47364f9034ceab83e38a6266f1-400x141.png',
              16808:
                'https://cdn.sanity.io/images/mavwf57a/production/052d85e593399ca302da16156cf0df13eed2af99-400x141.png',
              3709: 'https://cdn.sanity.io/images/mavwf57a/production/10497278e15253bc8f7b139d92acd5c27a8e1070-400x141.png',
              3685: 'https://cdn.sanity.io/images/mavwf57a/production/b0ad17f6532a9445022289c9e822433cb7006b3a-400x141.png',
              17135:
                'https://cdn.sanity.io/images/mavwf57a/production/14305ec6ee0f818e5a7079a3620891dbc0dba422-400x141.png',
              32729:
                'https://cdn.sanity.io/images/mavwf57a/production/14305ec6ee0f818e5a7079a3620891dbc0dba422-400x141.png',
            },
          },
          scrollerControls: 3,
          showScorePreview: true,
          variants: {
            BannerEventBox: 4,
          },
        },
        tokens: {
          OddBox: {
            borderWidth: 1,
            borderColor: '#93346a00',
            background: '#0164abba',
            oddColor: '#95fb3fe6',
            labelColor: '#95fb3fe6',
          },
          OddBoxSelected: {
            background: '#109fe9c7',
            oddColor: '#95fb3fe6',
            labelColor: '#95fb3fe6',
          },
        },
        container: document.getElementById('topscores'),
      });
      setIsWidgetReady(true);
    };
    if (isScriptLoaded) {
      initAltenar();
    }

    return () => {
      if ((widgetRef as any).current) {
        (widgetRef as any).current.remove();
      }
    };
  }, [integration, isScriptLoaded]);

  const searchParams = useSearchParams();
  const emailVerification = searchParams.get('emailVerified');
  const id = searchParams.get('id');
  const TxnId = searchParams.get('clientTransactionId');

  useEffect(() => {
    const checkConfirmation = async () => {
      if (id && TxnId) {
        dispatch({ type: 'SET_LOADING', payload: true });
        const data = {
          id: Number(id),
          clientTxId: TxnId,
        };
        try {
          const response = await fetchConfirmationPayphone(data);
          if (response.processed) {
            dispatch({ type: 'SET_LOADING', payload: false });
            toast({
              title: 'Depósito Creado',
              description: 'El depósito se ha creado exitosamente',
              variant: 'default',
              className: 'bg-sivarbet-secondary',
            });
          } else {
            console.log(
              'Error al confirmar el depósito / checkConfirmation method / response.processed',
            );
            dispatch({ type: 'SET_LOADING', payload: false });
            toast({
              title: 'Error',
              description: 'Error al confirmar el depósito.',
              variant: 'destructive',
            });
          }
        } catch (error) {
          console.log(
            'Error al confirmar el depósito / checkConfirmation method: ',
            error,
          );
          dispatch({ type: 'SET_LOADING', payload: false });
          toast({
            title: 'Error',
            description: 'Error al confirmar el depósito.',
            variant: 'destructive',
          });
        } finally {
          const url = new URL(window.location.href);
          url.search = '';
          window.history.replaceState({}, '', url.toString());
        }
      }
    };

    checkConfirmation();
  }, [id, TxnId, toast]);

  useEffect(() => {
    const haveWelcomeBonuses = bonuses.spins.some((bonus: any) => {
      return bonus.bonus.dispatcher === BonusDispatcher.REGISTER && bonus.status === BonusStatusCasino.PENDING;
    })
      || bonuses.sport.some((bonus: any) => {
        return bonus.bonus.dispatcher === BonusDispatcher.REGISTER && bonus.bonus_status === BonusStatusSport.PENDING;
      });
    setIsBonusStepsOpen(status === AUTHENTICATED_SESSION
      && account
      && haveWelcomeBonuses
      && (account.kycLevel !== UserKYCLevel.APPROVED
        || account.fullProfile !== true
        || account.emailVerified !== true));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account, status]);

  return (
    <>
      {isBonusStepsOpen && <BonusSteps />}
      <Script
        src={`${scriptUrl}/altenarWSDK.js`}
        strategy="lazyOnload"
        onReady={() => {
          setIsScriptLoaded(true);
        }}
      />
      {state.isLoading && <LoadingSpinner />}
      <div
        className="text-sivarbet-text flex flex-col items-stretch w-full"
        id="mainTab"
        role="tabpanel"
        tabIndex={0}
      >
        <Suspense fallback={<LoadingSpinner />}>
          <BannerCarousel isSession={status === AUTHENTICATED_SESSION} />
        </Suspense>
        <div className="mt-3">
          <Link href="/deportes" passHref className="flex items-center gap-2">
            <span className="icon icon-calendar text-l" />
            <h2 className="text-base sm:text-sm md:text-lg lg:text-xl">
              Eventos Populares
            </h2>
          </Link>
          <div id="topscores" className="mt-6 max-lg:mt-1" />
          {!isWidgetReady && (
            <div className="mt-6 max-lg:mt-1 flex gap-2">
              <Skeleton className="h-[134.5px] w-full sm:w-[400px] rounded-md bg-sivarbet-border" />
              <div className="hidden sm:flex gap-2">
                <Skeleton className="h-[134.5px] w-[400px] rounded-md bg-sivarbet-border" />
                <Skeleton className="h-[134.5px] w-[400px] rounded-md bg-sivarbet-border" />
              </div>
            </div>
          )}
        </div>
        <div className="flex flex-col items-stretch mt-5 max-lg:mt-2 max-md:mt-2">
          <LeagueSlider />
        </div>
        <div className="flex flex-col items-stretch w-full pt-5 max-lg:pt-0 max-md:pt-0">
          <Suspense fallback={<LoadingSpinner />}>
            <SWSmoothCarousel data={categories || []} onFavoritesUpdate={updateFavoritesOptimistically} />
          </Suspense>
        </div>
      </div>
      {emailVerification && (
        <EmailVerificationModal param={emailVerification} session={session} />
      )}
    </>
  );
};

export default memo(HomeTab);
