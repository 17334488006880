'use client';

import React, {
  useEffect, useState, useCallback,
} from 'react';

import Image from 'next/image';
import Link from 'next/link';

// import { useRouter } from 'next/navigation';

import { useRouter } from 'next/navigation';

import { useSession } from 'next-auth/react';
import { HiOutlineCurrencyDollar } from 'react-icons/hi2';

import { IoHeartOutline, IoHeartSharp } from 'react-icons/io5';

import { useAuthStore } from '@/stores/userStore';
import { userUIStore } from '@/stores/userUIStore';

import { updateFavoriteGames } from '@/utils/core';

import { CarouselData } from '@/interfaces/core';

import AcceptModal from '../modals/AcceptModal';
import RegistrationPopup from '../modals/RegistrationPopup';
import { Button } from '../ui/button';
import { Card, CardContent } from '../ui/card';
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from '../ui/carousel';
import {
  Tooltip, TooltipContent, TooltipProvider, TooltipTrigger,
} from '../ui/tooltip';

interface CarouselGamesProps {
  data: CarouselData[];
  onFavoritesUpdate: (newFavorites: string[]) => Promise<void>;
}

const CarouselGames: React.FC<CarouselGamesProps> = ({ data, onFavoritesUpdate }) => {
  const [favorites, setFavorites] = useState<string[]>([]);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isAcceptModalUrl, setIsAcceptModalUrl] = useState<string>('');
  const { data: session } = useSession();
  const { account, balance, updateFavoriteGames: UpdateFavoriteGamesStore } = useAuthStore();
  const [optimisticFavorites, setOptimisticFavorites] = useState<string[]>(account.favoriteGames || []);

  useEffect(() => {
    setOptimisticFavorites(account.favoriteGames || []);
  }, [account.favoriteGames]);

  const {
    toggleLoginModal, toggleDepositModal, toggleDepositSheet, toggleAcceptModal,
  } = userUIStore();
  const router = useRouter();

  useEffect(() => {
    const savedFavorites = account.favoriteGames;
    if (savedFavorites) {
      setFavorites(savedFavorites);
    }
  }, [account.favoriteGames]);

  const isFavorite = useCallback((gameCode: string) => {
    return favorites.includes(gameCode);
  }, [favorites]);

  const toggleFavorite = useCallback(async (gameCode: string) => {
    if (!session) {
      toggleLoginModal();
      return;
    }

    // Optimistic update
    const newFavorites = isFavorite(gameCode) ? optimisticFavorites.filter((code) => { return code !== gameCode; }) : [...optimisticFavorites, gameCode];
    setOptimisticFavorites(newFavorites);

    try {
      const updatedFavorites = await updateFavoriteGames(newFavorites);
      UpdateFavoriteGamesStore(updatedFavorites);
      onFavoritesUpdate(updatedFavorites);
    } catch (error) {
      setOptimisticFavorites(account.favoriteGames || []);
    }
  }, [session, optimisticFavorites, isFavorite, toggleLoginModal, UpdateFavoriteGamesStore, onFavoritesUpdate, account.favoriteGames]);
  // const toggleFavorite = async (gameCode: string) => {
  //   if (!session) {
  //     toggleLoginModal();
  //   } else if (isFavorite(gameCode)) {
  //     const newFavorites = favorites.filter((code) => { return code !== gameCode; });
  //     setFavorites(newFavorites);
  //     const updatedFavorites = await updateFavoriteGames(newFavorites);
  //     UpdateFavoriteGamesStore(updatedFavorites);
  //     onFavoritesUpdate();
  //   } else {
  //     const newFavorites = [...favorites, gameCode];
  //     setFavorites(newFavorites);
  //     const updatedFavorites = await updateFavoriteGames(newFavorites);
  //     UpdateFavoriteGamesStore(updatedFavorites);
  //     onFavoritesUpdate();
  //   }
  // };

  return (
    <div className="flex flex-col gap-4">
      {data.map((carousel) => {
        return (
          <div className="w-full h-full flex flex-wrap" key={carousel.headline}>
            <div className="w-full">
              <Carousel
                className="mb-1 h-full w-full"
                opts={{
                  align: 'start',
                }}
              >
                <div className="flex items-center justify-between pb-3 max-lg:mt-1 max-md:mt-1 max-sm:mt-1 max-lg:pb-1 max-md:pb-1 max-sm:pb-1">
                  <div className="flex items-center gap-2 text-lg font-medium text-sivarbet-text">
                    {carousel.icon}
                    <Link href={carousel.url} passHref>
                      <h2 className="w-1/2 text-base sm:text-sm md:text-lg lg:text-xl font-medium">{carousel.headline}</h2>
                    </Link>
                  </div>
                  <div className="flex flex-row-reverse gap-3">
                    <CarouselNext className="static rounded-lg border-sivarbet-border flex items-center bg-transparent transform-none" />
                    <CarouselPrevious className="static rounded-lg border-sivarbet-border flex items-center bg-transparent transform-none" />
                  </div>
                </div>
                <CarouselContent className="flex h-full max-md:gap-1 max-md:ml-px">
                  {carousel?.items?.map((item) => {
                    return (
                      <CarouselItem
                        className="w-1/2 h-1/2 flex justify-center basis-1/3 sm:basis-1/3 md:basis-1/6 lg:basis-[13.5%] xl:basis-[13.5%] rounded-lg max-md:pl-0"
                        key={item.identifier}
                      >
                        <Card className="bg-transparent w-full h-full p-0 border-none flex justify-center items-center rounded-lg">
                          <CardContent className="w-full text-sivarbet-text p-0 flex flex-col justify-center items-center rounded-lg">
                            <div className="w-full flex items-center justify-center relative group hover:opacity-75">
                              <Image
                                className="w-full rounded-xl h-full cursor-pointer transition duration-300 ease-in-out hover:opacity-55"
                                src={`https://cdn.softswiss.net/i/s4/${item.provider}/${item.identifier.split(':')[1]}.png`}
                                alt={item.title}
                                width={200}
                                height={0}
                              />

                              <div className="flex absolute top-[5px] right-1 w-fit rounded-sm overflow-hidden shadow-sm shadow-black">
                                {item.sections?.nuevos && (
                                  <span className="text-sivarbet-border md:text-xs text-[10px] bg-green-500 md:px-2 p-0.5 md:font-semibold">
                                    Nuevo
                                  </span>
                                )}
                                {item.sections?.popular && (
                                  <span className="text-sivarbet-border md:text-xs text-[10px] bg-yellow-500 md:px-2 p-0.5 md:font-semibold">Popular</span>
                                )}
                                {item.sections?.recomendados && (
                                  <span className="text-sivarbet-border md:text-xs text-[10px] bg-red-600 md:px-2 p-0.5 md:font-semibold">Top</span>
                                )}
                              </div>
                              <Button
                                type="button"
                                className="hidden absolute hover:text-black bg-[radial-gradient(circle,_rgb(149,251,63)_100%,_rgb(70,252,109)_100%)] text-white group-hover:block font-semibold group !opacity-200"
                                onClick={() => {
                                  if (!session) {
                                    toggleLoginModal();
                                  } else if (session && !account.fullProfile) {
                                    setIsModalOpen(true);
                                  } else if (!balance[0].amount || balance[0].amount === 0) {
                                    if (window.innerWidth < 1000) {
                                      toggleDepositSheet();
                                    } else {
                                      toggleDepositModal();
                                    }
                                  } else if (item.usd_only) {
                                    toggleAcceptModal();
                                    setIsAcceptModalUrl(`/casino/play?provider=${item.provider}&gamecode=${item.identifier}&currency=${item.usd_only ? 'USD' : 'HNL'}`);
                                  } else router.push(`/casino/play?provider=${item.provider}&gamecode=${item.identifier}&currency=${item.usd_only ? 'USD' : 'HNL'}`);
                                }}
                              >
                                Jugar
                              </Button>
                            </div>
                            <div className="w-full flex justify-between items-center pt-1">
                              <p
                                className="text-sm max-md:text-xs font-medium text-sivarbet-text overflow-hidden whitespace-nowrap text-ellipsis w-3/4 max-w-[200px] max-lg:max-w-[120px] max-md:max-w-[110px] max-sm:max-w-[100px]"
                                title={item.title}
                              >
                                {item.title}
                              </p>
                              <div className="flex items-center">
                                {item.usd_only && (
                                  <TooltipProvider>
                                    <Tooltip>
                                      <TooltipTrigger asChild>
                                        <Button className="bg-transparent h-0 p-0 hover:bg-transparent">
                                          <HiOutlineCurrencyDollar className="text-xl text-sivarbet-secondary" />
                                        </Button>
                                      </TooltipTrigger>
                                      <TooltipContent className="bg-sivarbet-secondary-bg text-sivarbet-primary border-sivarbet-border">
                                        <p>Este juego es solo en USD (1 USD = 25 HNL)</p>
                                      </TooltipContent>
                                    </Tooltip>
                                  </TooltipProvider>
                                )}
                                <Button
                                  type="button"
                                  className="bg-transparent h-0 hover:bg-transparent px-0"
                                  onClick={() => { return toggleFavorite(item.identifier); }}
                                >
                                  {isFavorite(item.identifier) ? (
                                    <IoHeartSharp className="text-red-600 text-xl" />
                                  ) : (
                                    <IoHeartOutline className="text-xl" />
                                  )}
                                </Button>
                              </div>
                            </div>
                            <div className="w-full text-start mt-1">
                              <span className="text-xs text-sivarbet-secondary block truncate">
                                {item.producer}
                              </span>
                            </div>
                          </CardContent>
                        </Card>
                      </CarouselItem>
                    );
                  })}
                </CarouselContent>
              </Carousel>
            </div>
          </div>
        );
      })}
      {isModalOpen && <RegistrationPopup isOpen isGame />}
      <AcceptModal url={isAcceptModalUrl} />
    </div>
  );
};

export default React.memo(CarouselGames);
