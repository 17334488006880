/* eslint-disable no-console */
import {
  memo, useEffect, useRef, useState,
} from 'react';

import moment from 'moment';

import { core, messages } from '@/features/dashboard/localization';

import { useAuthStore } from '@/stores/userStore';

import { Button } from '@/components/ui/button';
import { Card, CardContent } from '@/components/ui/card';
import {
  Dialog, DialogContent, DialogHeader, DialogTitle, DialogDescription, DialogFooter,
} from '@/components/ui/dialog';
import { useToast } from '@/components/ui/use-toast';

interface Props {
  fullProfile: boolean | undefined;
  kycLevel: number | undefined;
  kycStatus: number | undefined;
  userId: number | undefined;
  // eslint-disable-next-line react/require-default-props
  center?: boolean;
}
const VerifyIdentity = ({
  fullProfile, kycLevel, kycStatus, userId, center = false,
}: Props) => {
  const [showModal, setShowModal] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const {
    account, setAccount, btnStateMetaMap, setBtnStateMetaMap,
  } = useAuthStore();
  const { toast } = useToast();

  useEffect(() => {
    const button = buttonRef.current;
    if (!showToast && kycStatus === 1) {
      setShowToast(true);
      toast({
        title: 'Verificación en Proceso',
        description: 'Por favor, espere al menos 20 minutos para que se complete la verificación y evitar duplicados. Después de este tiempo, verificar en perfil el estado de la verificación. Lamentamos las molestias ocasionadas.',
      });
    }
    const handleMetaMapSuccess = async (detail: any) => {
      console.log('MetaMap verification successful:', detail);
      setShowModal(true);
    };

    if (!showToast && kycStatus === 1) {
      setShowToast(true);
      toast({
        title: 'Verificación en Proceso',
        description: 'Por favor, espere al menos 10 minutos para que se complete la verificación y evitar duplicados. Después de este tiempo, verificar en perfil el estado de la verificación. Lamentamos las molestias ocasionadas.',
      });
    }

    if (button) {
      const handleMetaMapFailure = (error: any) => { console.error('MetaMap verification failed:', error); };
      const handleExited = (event: CustomEvent<any>) => { return handleMetaMapFailure(event.detail); };
      const handleLoaded = (event: CustomEvent<any>) => { return console.log('[METAMAP] Loaded', event.detail); };
      const handleUserStarted = (event: CustomEvent<any>) => { return console.log('[METAMAP] User started verification', event.detail); };
      const handleUserFinished = (event: CustomEvent<any>) => { return handleMetaMapSuccess(event.detail); };

      button.addEventListener('metamap:loaded', handleLoaded as EventListener);
      button.addEventListener('metamap:userStartedSdk', handleUserStarted as EventListener);
      button.addEventListener('metamap:userFinishedSdk', handleUserFinished as unknown as EventListener);
      button.addEventListener('metamap:exitedSdk', handleExited as EventListener);
    }
  }, [buttonRef]);

  useEffect(() => {
    console.log(' +++++++++++++++ ');
    console.log(btnStateMetaMap);
    console.log(' +++++++++++++++ ');

    if (btnStateMetaMap.status && btnStateMetaMap.date && account?.kycStatus !== 2) {
      const currentDate = new Date();
      const timeDiff = moment(currentDate).diff(moment(btnStateMetaMap.date), 'minutes');

      if (timeDiff > 10) setBtnStateMetaMap({ status: false, date: null });
    }
  }, [account?.kycStatus, btnStateMetaMap, setBtnStateMetaMap]);

  if (kycLevel === undefined || kycStatus === undefined || userId === undefined) return null;

  return (
    <>
      <Card className="w-full text-sivarbet-text rounded-lg border-none bg-sivarbet-secondary-bg">
        <CardContent className={`w-full h-full rounded-lg border-none  flex flex-col items-center ${center ? 'justify-center' : 'justify-start'} p-4 md:p-8 box-border text-xl shrink-0`}>
          <div className={`flex-1 flex flex-col rounded-lg bg-transparent justify-start gap-4 ${center ? 'items-center' : 'items-start'}`}>
            {kycLevel === 2 ? (
              <>
                <div className="w-7 h-7 rounded-full bg-green500 flex items-center justify-center">
                  <span className="icon icon-check text-sm" />
                </div>
                <div className="text-base font-small">
                  {core.verifiedProfile}
                </div>
                <div className="mt-auto w-full text-green500 font-medium">
                  {core.statusApproved}
                </div>
              </>
            ) : (
              <>
                <div className="w-9 h-9 rounded-full bg-sivarbet-primary flex items-center justify-center">
                  <div className="text-xl font-medium">3</div>
                </div>
                <div className="text-base font-medium">
                  {core.verifyIdentity}
                </div>
                <div className="text-sm font-medium text-sivarbet-secondary-text text-wrap">
                  {messages.identityVerification}
                </div>
                <div className="text-sm font-medium text-sivarbet-secondary-text text-wrap">
                  {(() => {
                    switch (kycStatus) {
                      case 0:
                        return core.statusInitial;
                      case 1:
                        return core.statusPending;
                      case 3:
                        return core.statusRejected;
                      default:
                        return null;
                    }
                  })()}
                </div>
                <div className="mt-auto w-full flex justify-center">
                  {kycLevel === 0 && (
                    <div className="mt-auto w-full text-sm font-medium text-sivarbet-secondary-text text-wrap">
                      Valida paso 1 y 2 para poder verificar tu identidad
                    </div>
                  )}
                  {(kycStatus === 1 || btnStateMetaMap.status) && (
                    <div className="mt-auto w-full text-sm font-medium text-sivarbet-secondary-text text-wrap">
                      Verificación pendiente
                    </div>
                  )}
                  {!btnStateMetaMap.status && kycLevel === 1 && fullProfile && (kycStatus === 0 || kycStatus === 3) && (
                    <div className="mt-auto w-full flex justify-center">
                      <metamap-button
                        onClick={() => {
                          setBtnStateMetaMap({ status: true, date: new Date() });
                          setAccount({ ...account, kycStatus: 1 });
                        }}
                        ref={buttonRef}
                        clientid="668fd5deab929e001d4976dd"
                        flowid={process.env.NEXT_PUBLIC_METAMAP_FLOW_ID || ''}
                        icon="none"
                        language="es"
                        color="#00abfb"
                        metadata={JSON.stringify({
                          fixedLanguage: 'es',
                          id: userId,
                          name: account?.name,
                          email: account?.email,
                        })}
                      />
                    </div>
                  )}

                </div>
              </>
            )}
          </div>
        </CardContent>
      </Card>
      <Dialog open={showModal} onOpenChange={setShowModal}>
        <DialogContent className="flex flex-col items-center justify-center">
          <DialogHeader className="text-center">
            <DialogTitle>Verificación en Proceso</DialogTitle>
            <DialogDescription>
              Por favor, espere al menos 20 minutos para que se complete la verificación y evitar duplicados. Después de este tiempo, verificar en perfil el estado de la verificación. Lamentamos las molestias ocasionadas.
            </DialogDescription>
          </DialogHeader>
          <DialogFooter className="w-full flex justify-center">
            <Button onClick={() => { return setShowModal(false); }} className="mt-4">
              Cerrar
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default memo(VerifyIdentity);
