/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';

import Image from 'next/image';
import Script from 'next/script';

import { useSession } from 'next-auth/react';
import { FaArrowRightLong } from 'react-icons/fa6';

import { UserKYCLevel, Vendors } from '@/enums/core';
import { useAuthStore } from '@/stores/userStore';

import { decrypt } from '@/utils/core';

import {
  Dialog,
  DialogContent,
  DialogHeader,
} from '@/components/ui/dialog';
import {
  Tabs, TabsContent, TabsList, TabsTrigger,
} from '@/components/ui/tabs';

import { bannerBonus } from '@/public/img/marketing/promotions';

import { EmailVerified, FullProfile, VerifyIdentity } from '../dashboard/components/card';

const BonusSteps = () => {
  const { account } = useAuthStore();
  const { data: session } = useSession();
  const [userId, setUserId] = useState<number | undefined>(undefined);

  useEffect(() => {
    const decryptUserId = async () => {
      try {
        if (session?.user?.data && session?.user?.tag) {
          const decryptedData = await decrypt(session.user.data, session.user.tag);
          const currentUserId = Number(decryptedData);
          setUserId(Number(currentUserId));
        }
      } catch (error) {
        console.error('Error al desencriptar el ID del usuario:', error);
      }
    };

    if (session && session?.user?.data && session?.user?.tag) decryptUserId();
  }, [session]);

  return (
    <Dialog defaultOpen>
      <DialogContent colorClose="text-white" className="bg-gradient-to-b from-sivarbet-border via-sivarbet-secondary-bg to-sivarbet-border max-md:w-full max-md:h-1/2 max-md:flex max-md:flex-col max-md:justify-center max-md:items-center max-md:gap-4 p-0">
        {account.kycLevel !== UserKYCLevel.APPROVED && (
          <Script
            src="https://web-button.metamap.com/button.js"
            strategy="lazyOnload"
            onReady={() => { console.log('button script loaded'); }}
          />
        )}
        <DialogHeader className="w-full">
          <div className="relative bg-gradient-to-b from-sivarbet-border via-sivarbet-secondary-bg to-sivarbet-border p-6 rounded-lg shadow-lg w-full h-auto">
            <Image src={bannerBonus} alt="Promotion" width={400} height={100} className="absolute top-0 left-0 w-full h-[80px] object-cover" />
            <div className="relative cursor-pointer">
              <div className=" absolute clipR rounded-e-2 bg-gradient-to-r inset-y-1 from-sivarbet-border form-10% via-sivarbet-primary-hover via-30% to-sivarbet-primary w-28" />
              <div className=" absolute clipL rounded-s-2 end-0 bg-gradient-to-r inset-y-1 from-sivarbet-primary from-50% via-sivarbet-primary-hover via-10% to-sivarbet-border t0-5% w-28" />
              <div className="relative w-10/12 h-[40px] mx-auto bg-gradient-to-r from-sivarbet-primary via-sivarbet-primary-hover to-sivarbet-primary text-white text-center py-3 px-6 rounded-3xl font-bold text-3xl blur-1">
                <p className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-sm xl:text-base w-full">
                  Pasos para reclamar tu bono
                </p>
                <div className="absolute w-8 xl:w-20 end-0 h-[80%] inset-y-1 bg-gradient-to-r from-sivarbet-primary  via-sivarbet-primary-hover  to-sivarbet-border rounded-full blur-sm" />
                <div className="absolute w-8 xl:w-14 start-0 h-[80%] inset-y-1 bg-gradient-to-r from-sivarbet-border via-sivarbet-primary-hover to-sivarbet-primaryrounded-full blur-sm" />
              </div>
            </div>
          </div>
        </DialogHeader>
        <Tabs defaultValue="1" className="max-md:h-full max-md:w-full max-md:flex max-md:flex-col max-md:justify-center max-md:items-center max-md:gap-4 p-5">
          <TabsList className="flex justify-evenly items-center bg-transparent mb-3 max-md:gap-4">
            <TabsTrigger
              value="1"
              className="rounded-full text-white bg-sivarbet-primary-hover data-[state=active]:bg-sivarbet-primary p-5"
            />
            <FaArrowRightLong className="text-white" />
            {
              account.provider !== Vendors.GOOGLE && (
                <>
                  <TabsTrigger
                    value="2"
                    className="rounded-full text-white bg-sivarbet-primary-hover data-[state=active]:bg-sivarbet-primary p-5"
                  />
                  <FaArrowRightLong className="text-white" />
                </>
              )
            }
            <TabsTrigger
              value={account.provider !== Vendors.GOOGLE ? '3' : '2'}
              className="rounded-full text-white bg-sivarbet-primary-hover data-[state=active]:bg-sivarbet-primary p-5"
            />
          </TabsList>
          <TabsContent value="1">
            <FullProfile fullProfile={account?.fullProfile} center />
          </TabsContent>
          {
            account.provider !== Vendors.GOOGLE && (
              <TabsContent value="2">
                <EmailVerified emailVerified={account?.emailVerified} center />
              </TabsContent>
            )
          }
          <TabsContent value={account.provider !== Vendors.GOOGLE ? '3' : '2'}>
            <VerifyIdentity fullProfile={account?.fullProfile} kycLevel={account?.kycLevel} kycStatus={account?.kycStatus} userId={userId} center />
          </TabsContent>
        </Tabs>
      </DialogContent>
    </Dialog>
  );
};

export default BonusSteps;
